export const environment = {
  production: true,
  apiUrl: 'https://cms-api.aesthetic-works.net',
  baseApiPath: 'v1/site',
  website_uuid: '9f0737c4-1ce1-45cf-bb39-cdda3c953efd',
  sentry_public_dsn: null,
  re_captcha_site_key: '6LfqrKkUAAAAAL7p3wmUJpp4YWjnLLQYafCkzT1w',
  ads_tracking_id: null,
  analytics_tracking_id: null,
  facebook_pixel_id: null,
  release: 'APP_RELEASE',
  router_link_has_locale: true,
  ads_phone_conversion_label: null
};
