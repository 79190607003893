import { Component, Input } from '@angular/core';
import { Block } from './../../common-theme/models/index';
import { LocaleAwareBaseComponent } from './../../common-theme/index';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent extends LocaleAwareBaseComponent {
  @Input() block: Block;
  @Input() videoUri: string = null;
  @Input() blockImage1: Block;
  @Input() blockImage2: Block;
  @Input() blockImage3: Block;
}
