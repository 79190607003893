<header
  (window:scroll)="onScroll()"
  (window:resize)="onResize()"
>
  <nav
    class="main-nav"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <button
      type="button"
      class="main-menu-burger"
      [class.open]="menuIsOpen"
      (click)="toggleMenu()"
      fxHide.gt-sm
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
    <a
      class=logo-wrapper
      routerLink=""
    >
      <img
        src="./assets/svg/logo.svg"
        alt="1development"
      >
      <h1>1development</h1>
    </a>

    <div fxFlex class="site-nav-desktop">
      <ul
        fxLayout="row"
        fxLayoutAlign="space-evenly center"
      >
        <li *ngFor="let item of navItems">
          <a
            (click)="toggleMenu(); routeClick(item.link);"
          >
            <span translate [innerHTML]="item.name"></span>
          </a>
        </li>
      </ul>
    </div>

    <div
      class="main-menu-wrapper"
      fxLayout="row"
      fxLayoutAlign="center stretch"
    >
      <div
        class="contact-info-desktop"
        fxHide
        fxShow.gt-sm
      >
        <a
          [attr.href]="'tel:' + websiteService.website.phone"
          *ngIf="websiteService.website.phone"
        >
          <i class="material-icons">phone</i>
          <span>{{ websiteService.website.phone | beautify }}</span>
        </a>
      </div>
    </div>
  </nav>
  <nav
    class="site-nav"
    [ngClass]="{'open': (menuIsOpen || desktopShowNavMenu)}"
  >
    <div>
      <ul>
        <li *ngFor="let item of navItems">
          <a
            (click)="toggleMenu(); routeClick(item.link);"
          >
            <span translate [innerHTML]="item.name"></span>
          </a>
        </li>
      </ul>
      <div
        class="contact-info-mobile"
        fxHide.gt-sm
      >
        <a [attr.href]="'tel:' + websiteService.website.phone">
          <i class="material-icons">phone</i>
          <span>
            {{ websiteService.website.phone | beautify }}
          </span>
        </a>
      </div>
    </div>
  </nav>
</header>
