import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LocaleAwareBaseComponent } from './../../common-theme/seo/locale-aware-base.component';
import { LocaleService } from './../../common-theme/seo/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { WebsiteService } from './../../common-theme/api/website.service';
var HeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HeaderComponent, _super);
    function HeaderComponent(translate, router, window, localeService, websiteService, document) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        _this.router = router;
        _this.window = window;
        _this.localeService = localeService;
        _this.websiteService = websiteService;
        _this.document = document;
        _this.menuIsOpen = false;
        _this.lastScrollTop = 0;
        _this.navItems = [
            {
                name: 'Regulament',
                link: 'regulament'
            },
            {
                name: 'Locație',
                link: 'locatie'
            },
            {
                name: 'Tarife',
                link: 'tarife'
            },
            {
                name: 'Contact',
                link: 'contact'
            }
        ];
        _this.router.events.subscribe(function () {
            _this.menuIsOpen = false;
            _this.document.body.classList.remove('no-scroll');
        });
        return _this;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.desktopShowNavMenu = false;
    };
    HeaderComponent.prototype.toggleMenu = function () {
        if (this.window.innerWidth < 960) {
            this.menuIsOpen = !this.menuIsOpen;
            this.menuIsOpen ? this.document.body.classList.add('no-scroll') : this.document.body.classList.remove('no-scroll');
        }
    };
    HeaderComponent.prototype.onResize = function () {
        if (this.window.innerWidth >= 960) {
            this.menuIsOpen = false;
            this.desktopShowNavMenu = false;
            this.document.body.classList.remove('no-scroll');
        }
        else {
            this.desktopShowNavMenu = false;
        }
    };
    HeaderComponent.prototype.onScroll = function () {
        this.desktopShowNavMenu = false;
        var scrollToTop = this.document.getElementById('scroll_to_top');
        if (this.window.scrollY > 500)
            scrollToTop.classList.add('show');
        else
            scrollToTop.classList.remove('show');
    };
    HeaderComponent.prototype.routeClick = function (link) {
        document.getElementById(link).scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return HeaderComponent;
}(LocaleAwareBaseComponent));
export { HeaderComponent };
