import * as tslib_1 from "tslib";
import { Block } from './../../common-theme/models/index';
import { LocaleAwareBaseComponent } from './../../common-theme/index';
var ShowcaseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ShowcaseComponent, _super);
    function ShowcaseComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.videoUri = null;
        return _this;
    }
    return ShowcaseComponent;
}(LocaleAwareBaseComponent));
export { ShowcaseComponent };
