<app-showcase
  [block]="blockHelper.filterBlocksByKey(blocks, 'showcase')"
  [blockImage1]="blockHelper.filterBlocksByKey(blocks, 'showcase')"
></app-showcase>

<section
  id="regulament"
  class="container"
>
  <h2>
    Regulament
  </h2>

  <div class="pdf">
    <a
      href="/assets/regulament.pdf"
      target="_blank"
    >
      Click pentru varianta completa
      <br>
      <br>
      <img src="/assets/regulament-simplu-1.svg">
      <img src="/assets/regulament-simplu-2.svg">
    </a>
  </div>
</section>

<section
  id="locatie"
  class="container"
>
  <h2>
    Locație
  </h2>

  <iframe
    class="gmaps"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13361.263350754045!2d26.107070287310215!3d44.452227975457305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f96e12ab629d%3A0xb163fa1cbfb4f3fc!2sCentral%20Parking!5e0!3m2!1sen!2sro!4v1608112410900!5m2!1sen!2sro"
    frameborder="0"
    allowfullscreen=""
    aria-hidden="false"
    tabindex="0"
   ></iframe>
</section>

<section
  id="tarife"
  class="container"
>
  <h2>
    Tarife
  </h2>

  <div class="prices">
    <p>
      Tariful pentru parcare este orar și nu poate fi fracționat. Pentru evitarea oricărui dubiu, deținătorii cardului de acces nu vor plăti tariful de parcare, acesta fiind datorat doar de persoanele care dețin tichetul de acces. Parcarea cu plată din complexul Central va putea fi folosita doar prin achitarea tarifelor prevăzute în prezentul Regulament.
    </p>
    <ol class="letters">
      <li>
        Tariful orar pentru parcare pentru locurile supraterane este de 7 lei / oră (T.V.A. inclus).
      </li>
      <li>
        Tariful orar pentru parcare pentru locurile din subteran, de la subsol -1 este de 5 lei / oră (T.V.A. inclus)
      </li>
      <li>
        Daca utilizatorul parcarii stationeaza in parcare maxim 29 minute, va achita 3 lei, urmand ca daca se depaseste timpul de 30 minute tariful de parcare sa se achite integral pentru fiecare ora stationata.
      </li>
      <li>
        Tariful orar pentru locurile de parcare situate la subsol -1 este de 5 lei / oră (T.V.A. inclus).
      </li>
      <li>
        Tarifele aferente abonamentelor sunt valabile doar pentru locurile de parcare situate in subteran, la nivelul -1 de la subsol.
      </li>
      <li>
        Prin plata unui abonament, clientul beneficiaza de un loc de parcare nominal la nivelul -1 al parcarii pe toata durata de valabilitate a abonamentului. Preturile abonamentelor sunt dupa cum urmeaza:
        <ol class="numbers">
          <li>
            1 săptămână - 200 lei;
          </li>
          <li>
            Abonament lunar - 600 lei;
          </li>
          <li>
            Abonament anual - 6000 lei.
          </li>
        </ol>
      </li>
    </ol>
  </div>
</section>

<app-inquiry id="contact">
</app-inquiry>
