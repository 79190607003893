<div
  class="showcase"
  fxLayout="row"
>
  <div fxFlex="100%" [ngStyle]="{'background-image': 'url(' + blockImage1.image.url + ')'}"></div>
  <div class="showcase-content container">
    <h1>{{ block.properties.title }}</h1>
    <span>{{ block.properties.subtitle }}</span>
    <a *ngIf="block.properties.link" class="btn-box--white" [routerLink]="basePath + block.properties.link">
      {{ block.properties.button }}
    </a>
  </div>
</div>
