import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      blocks_categories: ['homepage']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
