import { Routes } from '@angular/router';
import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
var ɵ0 = {
    blocks_categories: ['homepage']
};
var routes = [
    {
        path: '',
        component: HomeComponent,
        data: ɵ0,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: ''
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
