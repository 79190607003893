<section class="inquiry-page">
  <div class="inquiry-header">
    <h2 class="special-letter-wrapper">{{ 'Contact' | translate }}</h2>
  </div>

  <div class="container">
    <div class="contact">
      <a
        target="_blank"
        [href]="'tel:' + websiteService.website.phone"
      >
        {{ websiteService.website.phone | beautify }} |
      </a>
      <a
        target="_blank"
        [href]="'mailto:' + websiteService.website.email"
      >
        {{ websiteService.website.email }}
      </a>
    </div>

    <app-contact-form-base>
    </app-contact-form-base>

    <address>
      <a
        target="_blank"
        [href]="blockHelper.filterBlocksByKey(blocks, 'address').properties.link"
      >
        {{ blockHelper.filterBlocksByKey(blocks, 'address').properties.text }}
      </a>
    </address>
  </div>
</section>
